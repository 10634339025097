import { useEffect, useMemo } from "react";
import type { AppData } from "~/remix";
import { useActionData as rmxUseActionData, useMatches } from "~/remix";

import { useActionData, useNavigate, useNavigation } from "@remix-run/react";
import type { UseDataFunctionReturn } from "remix-typedjson";
import type { User } from "~/models/user.server";
import _ from "./helpers/utils";
import type { loader } from "./root";
import { useUserHasRole } from "./services/user";

const DEFAULT_REDIRECT = "/";

/**
 * This should be used any time the redirect path is user-provided
 * (Like the query string on our login/signup pages). This avoids
 * open-redirect vulnerabilities.
 * @param {string} to The redirect destination
 * @param {string} defaultRedirect The redirect to use if the to is unsafe.
 */
export function safeRedirect(
  to: FormDataEntryValue | string | null | undefined,
  defaultRedirect: string = DEFAULT_REDIRECT
) {
  if (!to || typeof to !== "string") {
    return defaultRedirect;
  }

  if (!to.startsWith("/") || to.startsWith("//")) {
    return defaultRedirect;
  }

  return to;
}

export function useMatchesData<T = AppData, R = UseDataFunctionReturn<T>>(
  id: string
): R | undefined {

  const matchingRoutes = useMatches();
  const route = useMemo(
    () => matchingRoutes.find((route) => route.id === id),
    [matchingRoutes, id]
  );

  return route?.data as R;
}

function isUser(user: any): user is User {
  return user && typeof user === "object" && typeof user.email === "string";
}

export function useOptionalUser(): User | undefined {
  const data = useMatchesData("root");
  if (!data || !isUser(data.user)) {
    return undefined;
  }
  return data.user;
}

export function useUser(roles?: string[]): User {
  const maybeUser = useOptionalUser();
  const hasRole = useUserHasRole(maybeUser, roles)

  if (!maybeUser) {
    throw new Error(
      "No user found in root loader, but user is required by useUser. If user is optional, try useOptionalUser instead."
    );
  }

  if (!hasRole) {
    throw new Error(
      `User doesn't have the required role(s) (${(roles ?? []).join(',')}).`
    );
  }

  return maybeUser;
}

export function validateEmail(email: unknown): email is string {
  return typeof email === "string" && email.length > 3 && email.includes("@");
}

export function validateGameId(gameId: unknown): gameId is string {
  return typeof gameId === "string" && gameId.length > 3;
}

export function useActionDataRedirect<T = AppData>(onData?: (datas: T) => void | boolean) {

  const datas = useActionData<T>();
  const navigate = useNavigate();

  useEffect(() => {
    if (datas && typeof datas === 'object' && '__redirectTo' in datas) {

      const { __redirectTo } = datas;
      let doRedirect = true;

      if (onData && typeof onData === 'function') {
        doRedirect = onData(datas as T) ?? doRedirect;
      }

      if (typeof __redirectTo === 'string' && doRedirect) {
        const to = setTimeout(() => {
          navigate(datas.__redirectTo!);
        }, 20)

        return () => {
          clearTimeout(to);
        }
      }
    }
  }, [datas])

}

export function useEffectOnActionData<T = AppData>(keyName: keyof T, onData: (target: any, wholeDatas: T) => void | boolean) {
  const datas = rmxUseActionData<T>();
  const nav = useNavigation()
  useEffect(() => {
    if (nav.state !== 'idle' || !datas) return;
    if (datas && typeof datas === 'object') {
      if (keyName in datas) {
        onData(datas[keyName as keyof typeof datas], datas as T)
      }
    }
  }, [datas, keyName, nav.state, onData])

}

export function exclude<T, Key extends keyof T>(
  entity: T,
  keys: Key[]
): Omit<T, Key> {
  for (let key of keys) {
    delete entity[key]
  }
  return entity;
}

export function pick<T, Key extends keyof T>(
  entity: T,
  keys: Key[]
): Extract<T, Key> {
  const ret = entity;
  if (typeof ret === 'object') {
    // @ts-expect-error
    Object.keys(ret).forEach(kName => {
      if (!keys.map(String).includes(String(kName))) {
        // @ts-expect-error
        delete ret[kName as keyof typeof ret]
      }
    })
  }
  return ret as Extract<T, Key>;
}


export const useGetAppBuildInfos = () => {

  let datas = useMatchesData<typeof loader>('root');
  if (!datas) {
    throw "Could not find build informations";
  }

  const { buildInfos } = datas;
  return buildInfos;
}

export const useGetAppHash = (len = 7) => {
  const { hash } = useGetAppBuildInfos();
  return hash?.substring(0, len);
}

export const useGetAppBranch = () => {
  const { branch } = useGetAppBuildInfos();
  return branch;
}

export const useGetAppBuildDate = () => {
  const { date } = useGetAppBuildInfos();
  return new Date(date ?? '');
}

export const useGetAppVersion = () => {
  const { version } = useGetAppBuildInfos();
  return {
    version,
    versionHTML: version ?? ''
      .replace('alpha', '&alpha;')
      .replace('beta', '&beta;')
    ,
  };
}

export const useGetAppVersionString = () => {
  const hash = useGetAppHash();
  const branch = useGetAppBranch();
  const date = useGetAppBuildDate();

  return `Version: ${hash}\nBranch: ${branch}\nDate: ${date?.toLocaleString()}`;
}

export const excludeKeys = exclude
export const OmitKeys = exclude

export const LeftQuote = '«';
export const RightQuote = '»';

export const quote = (text: string | number) => `${LeftQuote} ${text} ${RightQuote}`;

export const reRenderingDebug = (text: string, output?: React.ReactNode) => {
  if (_.isDev()) {
    console.count(`Rerendering : ${text}`) // FIXME: console.count
  }
  return output;
}
export const useRenderingDebug = (text: string, output?: React.ReactNode) => {
  useEffect(() => {
    reRenderingDebug(text, output)
  }, [text, output])
  return output
}