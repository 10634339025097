import type { User } from "@prisma/client";
import { useOptionalUser } from "~/utils";

export const xUserHasRole = (user?: User, roles?: string | string[]) => {
  if (!user) return false;
  if (!roles) return true;
  roles = (Array.isArray(roles) ? roles : roles.split(',')).map(s => s.trim());
  if (user) {
    const inter = roles.filter(v => user.roles.split(',').includes(v));
    return inter.length > 0;
  }
  return false;
}

export const useUserHasRole = (user?: User, roles?: string | string[]) => {
  return xUserHasRole(user, roles);
}

export const useHasRole = (roles?: string | string[], user?: User) => {
  const oUser = useOptionalUser();
  const ret = useUserHasRole(user ?? oUser, roles);
  return ret;
}

export const xUseGetUserSpecialRoles = (user: User | undefined) => {

  return {
    user: user,
    isUser: xUserHasRole(user, 'role_user'),
    isGM: xUserHasRole(user, ['role_gm', 'role_gmpp']),
    isTier1: xUserHasRole(user, ['role_tier1']),
    isTier2: xUserHasRole(user, ['role_tier2']),
    isTier3: xUserHasRole(user, ['role_tier3']),
    isAdmin: xUserHasRole(user, 'role_admin'),
  };
}

export const useGetUserSpecialRoles = (user?: User) => {
  const oUser = useOptionalUser();
  const tmpUser = user ?? oUser;

  return xUseGetUserSpecialRoles(tmpUser)
}